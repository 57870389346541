// import { TitleWithTextAndButton } from 'components/TitleWithTextAndButton';
import { Company_company_list, Offer_offer_list } from 'generated/api';
import { EmptyState } from 'components/EmptyState';
import { ListHeader } from 'components/ListHeader';
import { MaxWidth } from 'components/MaxWidth';
import { OfferList } from 'components/OfferList';
import { Page } from 'components/Page';
import { useActiveCompany } from 'hooks/ActiveCompanyProvider';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useOffersWithPagination } from 'utils/api';
import { VerticalMargin } from 'components/VerticalMargin';
import type { NextPage } from 'next';
import { PaginationData } from 'utils/pagination';
import { PaginationContainer } from 'components/PaginationContainer';
import styles from './index.module.css';

const Home: NextPage = () => {
  const { activeCompany, refreshCompanies } = useActiveCompany();
  const [offersPage, setOffersPage] = useState(1);
  const { data: offersData, isLoading } = useOffersWithPagination({ page: offersPage });

  const offers = offersData?.data;
  const offersPagination = offersData?.pagination;

  useEffect(() => {
    refreshCompanies();
  }, [refreshCompanies]);

  return (
    <Page title="Dashboard" needsAuth loading={isLoading}>
      <MaxWidth>
        <VerticalMargin size="extralarge">
          <ListHeader
            buttons={[
              {
                iconLeft: 'edit',
                children:
                  activeCompany?.status === Company_company_list.status.CONFIRMED
                    ? 'Ingediend bij redactie'
                    : 'Bedrijfspagina beheren',
                href: `/bedrijven/${activeCompany?.uuid}/bewerken`,
                disabled: activeCompany?.status === Company_company_list.status.CONFIRMED,
              },
              // {
              //   iconLeft: 'add',
              //   children: 'Campagne starten',
              //   href: '/aanbiedingen/aanmaken',
              //   disabled: activeCompany?.status !== Company_company_list.status.SUBMITTED,
              // },
            ]}
            title="Dashboard"
          />
        </VerticalMargin>

        <VerticalMargin size="extralarge">
          <MainComponent
            offersPage={offersPage}
            offersPagination={offersPagination}
            setOffersPage={setOffersPage}
            activeCompany={activeCompany}
            offers={offers}
          />
        </VerticalMargin>

        {/* <TitleWithTextAndButton
          button={{ label: 'Naar veelgestelde vragen', href: '/' }}
          text="We hebben de meest voorkomende vragen & antwoorden voor jou op een rij gezet om je zo goed mogelijk te helpen."
          title="Vragen?"
        /> */}
      </MaxWidth>
    </Page>
  );
};

const MainComponent = ({
  activeCompany,
  offers,
  setOffersPage,
  offersPagination,
}: {
  activeCompany?: Company_company_list;
  offers?: Offer_offer_list[];
  offersPage: number;
  setOffersPage: Dispatch<SetStateAction<number>>;
  offersPagination?: PaginationData;
}) => {
  if (!activeCompany?.uuid) {
    return;
  }

  return !activeCompany.profileEmpty && offers && offers.length > 0 ? (
    <PaginationContainer
      showPagination={offers?.length !== 0}
      numberOfPages={offersPagination?.lastPage || 0}
      currentPage={offersPagination?.currentPage || 0}
      goToPage={setOffersPage}
    >
      <OfferList canCreateOffer={activeCompany.status === Company_company_list.status.SUBMITTED} offers={offers} />
    </PaginationContainer>
  ) : (
    <div className={styles.emptyStateContainer}>
      <EmptyState
        button={{
          href: `https://www.dpgdirect.nl/direct/utm_source=campaign_mail&utm_medium=campaign_mail&utm_campaign=indebuurt_klantportaal&utm_content=mail_starttegoed_indebuurt`,
          label: 'Banner advertentie starten',
          iconRight: 'externalLink',
          target: '_blank',
        }}
        text="Met een banner campagne kun je heel gericht adverteren in jouw regio. Met DPG Direct is het in een paar stappen geregeld. Nu met 100 euro starttegoed."
        icon="megaphone"
        title="Banner advertentie campagne"
        subText="Naar DPG Direct"
      />
      {activeCompany.status === Company_company_list.status.CONFIRMED ? undefined : (
        <EmptyState
          button={{
            href: `/bedrijven/${activeCompany.uuid}/bewerken`,
            label: 'Bedrijfsgegevens aanvullen',
          }}
          text="Om je bedrijf te promoten, heb je een gidspagina nodig op indebuurt. Daarnaast zorgt deze pagina ervoor dat jouw bedrijf zichtbaar is op indebuurt."
          icon="building"
          title="Jouw bedrijfspagina"
        />
      )}
    </div>
  );
};

export default Home;
